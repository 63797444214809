import { createGlobalStyle } from "styled-components";
import { theme } from "../theme/index";
import "../styles/fonts.css";

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    text-rendering: optimizeLegibility;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
	body {
    background-color: ${(p) => theme.colors.white};
    font-family: "Roboto", 'Helvetica', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
    color: ${(p) => theme.colors.darkGrey};
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
	}
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li {
    margin: 0;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  button {
    cursor: pointer;
    text-decoration: none;
  }
`;

export default GlobalStyles;
