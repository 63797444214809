import styled from "styled-components";
import { theme } from "../../../assets/theme";

export const Background = styled.div`
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${theme.colors.blue};
    display: flex;
    flex-direction: column;
    row-gap: 7rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        row-gap: unset;
    }
`;
