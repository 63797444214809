import React, { useState, useEffect } from "react";
import LoveTheSound from "../../MainContentComponents/LoveTheSound";
import SimpleSteps from "../../MainContentComponents/SimpleSteps";
import GreatTips from "../../MainContentComponents/GreatTips";
import BottomView from "../../MainContentComponents/BottomView";
// import SignUpSection from "../SignUpSection";
import MainContentBackground from "../../../assets/svg/MainContentBackground.svg";
import { Background } from "./MainContent.styles";
import { breakpointValues } from "../../../assets/theme/breakpoints";

const AppContent = () => {
    const [background, setBackground] = useState("");

    useEffect(() => {
        showMobileBackground();
    }, []);

    window.addEventListener("resize", () => {
        showMobileBackground();
    });

    const showMobileBackground = () => {
        switch (true) {
            case window.innerWidth > breakpointValues.tablet:
                setBackground(MainContentBackground);
                return;
            case window.innerWidth <= breakpointValues.tablet:
            default:
                setBackground("");
                return;
        }
    };

    return (
        <>
            <LoveTheSound />
            <Background background={background}>
                <SimpleSteps />
                <GreatTips />
                {/* <SignUpSection /> */}
                <BottomView />
            </Background>
        </>
    );
};

export default AppContent;
