import React, { useState, useEffect } from 'react';
import SimpleStepPhone from '../../../assets/svg/SimpleStepPhone.svg';
import SimpleStepMobilePhone from '../../../assets/svg/SimpleStepMobilePhone.svg';
import { landingPageTexts } from '../../../utils/textService';
import { scrollToTop } from '../../../utils/scrollToTop';
import { showCustomGeo } from '../../../utils/showCustomGeo';
import { RoundedPinkRectangle, RoundedPinkRectangleMobile } from '../../Geometrics/index';
import { 
    SimpleStepsContainer,
    SimpleStepsContent,
    SimpleStepsNumber,
    SimpleStepsTitle,
    SimpleStepsDescription,
    SimpleStepPhoneImg,
    SimpleStepsImageWrapper,
    SimpleStepsButton,
    PinkCylinderWrapper
} from './SimpleSteps.styles';

const SimpleSteps = () => {
    const [isMobileView, setIsMobileView] = useState(false);

    const checkInnerWidth = () => {
        if (window.innerWidth < 540) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }

    useEffect(() => {
        checkInnerWidth();
    }, []);

    window.addEventListener('resize', () => {
        checkInnerWidth();
    });

    const renderRespCylinder = () => {
        if (showCustomGeo()) {
            return (
                <PinkCylinderWrapper>
                    {window.innerWidth < 540 ? <RoundedPinkRectangleMobile /> : <RoundedPinkRectangle />}
                </PinkCylinderWrapper>
            )
        }
        return null;
    }

    return (
        <SimpleStepsContainer>
            <SimpleStepsContent>
                <SimpleStepsNumber>
                    {landingPageTexts('simpleStepsNumber')}
                </SimpleStepsNumber>
                <SimpleStepsTitle>
                    {landingPageTexts('simpleStepsTitle')}
                </SimpleStepsTitle>
                <SimpleStepsDescription>
                    {landingPageTexts('simpleStepsContentText')}
                </SimpleStepsDescription>
                <SimpleStepsButton onClick={scrollToTop}>
                    {landingPageTexts('tryItTodayButtonText')}
                </SimpleStepsButton>
            </SimpleStepsContent>
            <SimpleStepsImageWrapper>
                {renderRespCylinder()}
                <SimpleStepPhoneImg src={!isMobileView ? SimpleStepPhone : SimpleStepMobilePhone} />
            </SimpleStepsImageWrapper>
        </SimpleStepsContainer>
    )
}

export default SimpleSteps;
