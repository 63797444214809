import styled from "styled-components";
import { theme } from "../../../assets/theme";

export const SimpleStepsContainer = styled.div`
    min-height: 100vh;
    display: flex;
    width: 80%;
    margin: 5rem auto;
    justify-content: space-around;

    @media (max-width: ${theme.breakpoints.tablet}) {
        flex-direction: column;
        margin: 0;
        height: unset;
        width: 100%;
        position: relative;
    }
`;

export const SimpleStepsImageWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin: auto 0;

    @media (max-width: ${theme.breakpoints.tablet}) {
        height: unset;
        justify-content: center;
        position: relative;
    }
`;

export const SimpleStepsContent = styled.div`
    width: 50%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    color: ${theme.colors.white};
    position: relative;
    z-index: 1;
    background-color: transparent;

    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 100%;
        padding: 4rem;
        margin: 0;
        #height: 90vh;
    }
`;

export const SimpleStepsNumber = styled.p`
    font-size: 16px;
`;

export const SimpleStepsTitle = styled.h1`
    font-weight: 300;
    font-size: 68px;
    line-height: 76px;
    margin-left: -0.3rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 40px;
        line-height: 48px;
    }
`;

export const SimpleStepsDescription = styled.p`
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const SimpleStepPhoneImg = styled.img`
    max-width: 40rem;
    max-height: 40rem;
    position: relative;

    @media (max-width: 500px) {
        width: unset;
        height: unset;
    }

    @media (min-width: 550px) {
        width: 100%;
        height: 100%;
    }
`;

export const SimpleStepsButton = styled.button`
    background-color: ${theme.colors.white};
    border-radius: 37px;
    color: ${theme.colors.blue};
    width: 205px;
    border: none;
    padding: 16px 40px;
    margin-top: 1rem;
    font-weight: 600;
`;

export const PinkCylinderWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 5vh;
`;
