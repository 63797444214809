import * as React from "react"

const RoundedPinkRectangle = () => (
    <svg
    width={'95vw'}
    height={715}
    fill="none"
  >
    <path
      d="M1141.6 604.544c-40.87 92.956-149.344 135.232-242.294 94.427L110.039 352.478c-92.95-40.805-135.174-149.24-94.31-242.195C56.591 17.327 165.067-24.949 258.016 15.856l789.273 346.493c92.94 40.805 135.17 149.24 94.31 242.195Z"
      fill="#FDE5DF"
    />
  </svg>
)

export default RoundedPinkRectangle;
