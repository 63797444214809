import React, { useEffect, useState } from 'react';
import StartHearBackground from '../../../assets/svg/StartHearBackground.svg';
import StartHearMobileBackground from '../../../assets/svg/StartHearMobileBackground.svg';
import { AppStoreButton } from '../../index';
import { LeaLogo } from '../../index';
import { breakpointValues } from '../../../assets/theme/breakpoints';
import { landingPageTexts } from '../../../utils/textService';
import { appStoreLinks } from '../../../AppStoreButtons/AppStoreButtons';
import {
    StartHearContainer,
    StartHearContentWrapper,
    StartHearTopEmptyDiv,
    StartHearTextWrapper,
    StartHearTitle,
    StartHearDesciption,
    StartHearButtonContainer
} from './StartHearSection.styles';

const NewStartHear = () => {

    const AppButtons = appStoreLinks.map(store => (
        <AppStoreButton
            key={store.id}
            buttonTitle={store.title}
            buttonSubTitle={landingPageTexts(store.name)}
            appStore={store.name}
            icon={store.icon}
            link={store.url}
        />
    ))

    const [isMobileView, setIsMobileView] = useState(false);

    const checkInnerWidth = () => {
        if (window.innerWidth <= breakpointValues.tablet) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }

    useEffect(() => {
        checkInnerWidth();
    }, []);

    window.addEventListener('resize', () => {
        checkInnerWidth();
    });

    return (
        <StartHearContainer background={isMobileView ? StartHearMobileBackground : StartHearBackground}>
            <StartHearContentWrapper>
                {isMobileView && <StartHearTopEmptyDiv />}
                <LeaLogo />
                <StartHearTextWrapper>
                    <StartHearTitle>
                        {landingPageTexts('startHearTitle')}
                    </StartHearTitle>
                    <StartHearDesciption>
                        {landingPageTexts('startHearContentText')}
                    </StartHearDesciption>
                </StartHearTextWrapper>
                <StartHearButtonContainer>
                    {AppButtons}
                </StartHearButtonContainer>
            </StartHearContentWrapper>
        </StartHearContainer>
    )
}

export default NewStartHear;
