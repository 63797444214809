export const breakpointValues = {
  monitor: 1685,
  laptop: 1025,
  tablet: 820,
  mobile: 480,
};

export const breakpoints = {
  monitor: breakpointValues.monitor + "px",
  laptop: breakpointValues.laptop + "px",
  tablet: breakpointValues.tablet + "px",
  mobile: breakpointValues.mobile + "px",
};
