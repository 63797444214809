import React from 'react';
import { Link } from 'react-router-dom';
import { LeaLogoContainer } from './LeaLogoWithText.styles';
import LeaLogoWithTextImg from '../../assets/svg/LeaLogoWithTextImg.svg';
import LeaLogoWithTextImgMobile from '../../assets/svg/LeaLogoWithTextImgMobile.svg';

const LeaLogoWithText = ({ justify = 'center', backgroundColor }) => {
    return (
        <LeaLogoContainer justify={justify} backgroundColor={backgroundColor}>
            {
                window.location.pathname !== '/' ? (
                    <Link to={window.location.pathname !== '/' ? '/' : null}>
                        <img src={window.innerWidth <= 480 ? LeaLogoWithTextImgMobile : LeaLogoWithTextImg} alt="Lea logo in bottom" />
                    </Link>
                ) : (
                    <img src={window.innerWidth <= 480 ? LeaLogoWithTextImgMobile : LeaLogoWithTextImg} alt="Lea logo in bottom" />
                )
            }
        </LeaLogoContainer>
    )
}

export default LeaLogoWithText;
