import styled from "styled-components";
import { theme } from "../../assets/theme";

export const LeaLogoContainer = styled.div`
    background: ${theme.colors.blue};
    padding: ${props => props.padding};

    @media (max-width: 480px) {
        position: absolute;
        top: 1rem;
        left: 1rem;
        padding: 0;
    }
`;
