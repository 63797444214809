import React from "react";
import { SpinnerWrapper, SpinnerDot } from "./LoadingSpinner.styles";

const LoadingSpinner = () => {
  return (
    <>
      <SpinnerWrapper>
        <SpinnerDot delay={0} />
        <SpinnerDot delay={0.1} />
        <SpinnerDot delay={0.2} />
      </SpinnerWrapper>
    </>
  );
};

export default LoadingSpinner;
