import React from 'react';
import { landingPageTexts } from '../../../utils/textService';
import { AppStoreButton } from '../../index';
import { LeaLogoWithText } from '../../index';
import Footer from '../../Footer';
import { appStoreLinks } from '../../../AppStoreButtons/AppStoreButtons';
import { 
    BottomViewContainer,
    BottomViewContentWrapper,
    BottomViewTitle,
    BottomViewTextContent,
    BottomViewEmailText,
    ButtonWrapper
} from './BottomView.styles';

const BottomView = () => {

    const AppButtons = appStoreLinks.map(store => (
        <AppStoreButton
            key={store.id}
            buttonTitle={store.title}
            buttonSubTitle={landingPageTexts(store.name)}
            appStore={store.name}
            icon={store.icon}
            link={store.url}
        />
    ))

    const openMail = email => {
        window.location = `mailto:${email}`;
    }

    return (
        <BottomViewContainer>
            <BottomViewContentWrapper>
                <BottomViewTitle>
                    {landingPageTexts('bottomViewTitle')}
                </BottomViewTitle>
                <BottomViewTextContent>
                    {landingPageTexts('bottomViewContentTextContact')}
                    <BottomViewEmailText onClick={() => openMail(landingPageTexts('bottomViewEmailText'))}>
                        {landingPageTexts('bottomViewEmailText')}
                    </BottomViewEmailText>
                </BottomViewTextContent>
                <ButtonWrapper>
                    {AppButtons}
                </ButtonWrapper>
                <LeaLogoWithText />
            </BottomViewContentWrapper>
            <Footer />
        </BottomViewContainer>
    )
}

export default BottomView;
