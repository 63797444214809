import { landingPageTexts } from "../utils/textService";
import AppleLogo from '../assets/svg/AppleLogo.svg';
import GooglePlayLogo from '../assets/svg/GooglePlayLogo.svg';

export const appStoreLinks = [
        {
            id: 2,
            title: landingPageTexts('appStoreButtonText'),
            name: "appStore",
            url: "https://apps.apple.com/app/kai-hearing/id1573571711",
            icon: AppleLogo
        },
        {
            id: 4,
            title: landingPageTexts('googlePlayButtonText'),
            name: "googlePlay",
            url: "https://play.google.com/store/apps/details?id=com.gnhearing.kai",
            icon: GooglePlayLogo
        }
    ];
