import React from "react";
import {
  AppStoreButtonWrapper,
  AppStoreIcon,
  ButtonTextWrapper,
  ButtonTitle,
  AppStoreName,
} from "./AppStoreButton.styles";

const AppStoreButton = ({
  buttonTitle,
  buttonSubTitle,
  appStore,
  icon,
  link,
}) => {
  const transformedAppStoreName =
    appStore.charAt(0).toUpperCase() + appStore.slice(1);

  return (
    <AppStoreButtonWrapper href={link} target="_blank" rel="noreferrer">
      <AppStoreIcon src={icon} alt={`${transformedAppStoreName} Logo`} />
      <ButtonTextWrapper>
        <ButtonTitle>{buttonTitle}</ButtonTitle>
        <AppStoreName>{buttonSubTitle}</AppStoreName>
      </ButtonTextWrapper>
    </AppStoreButtonWrapper>
  );
};

export default AppStoreButton;
