import React, { useState, useEffect } from 'react';
import LoveTheSoundBackground from '../../../assets/svg/LoveTheSoundBackground.svg';
import LoveTheSoundBackgroundMobile from '../../../assets/svg/LoveTheSoundBackgroundMobile.svg';
import GroupOfPhones from '../../../assets/svg/GroupOfPhones.svg';
import { breakpointValues } from '../../../assets/theme/breakpoints';
import { landingPageTexts } from '../../../utils/textService';
import { scrollToTop } from '../../../utils/scrollToTop';
import {
    LoveTheSoundContainer,
    LoveTheSoundTitle,
    LoveTheSoundContent,
    LoveTheSoundNumber,
    LoveTheSoundDescription,
    LoveTheSoundDownloadButton,
    GroupOfPhonesWrapper,
    GroupOfPhonesImg
} from './LoveTheSound.styles';

const LoveTheSound = () => {
    const [isMobileView, setIsMobileView] = useState(false);

    const checkInnerWidth = () => {
        if (window.innerWidth <= breakpointValues.tablet) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }

    useEffect(() => {
        checkInnerWidth();
    }, []);

    window.addEventListener('resize', () => {
        checkInnerWidth();
    });

    const renderMobileGroupOfPhones = () => {
        if (isMobileView) {
            return (
                <GroupOfPhonesWrapper>
                    <GroupOfPhonesImg src={GroupOfPhones} />
                </GroupOfPhonesWrapper>
            )
        }
        return null;
    }

    return (
        <LoveTheSoundContainer background={!isMobileView ? LoveTheSoundBackground : LoveTheSoundBackgroundMobile}>
            {renderMobileGroupOfPhones()}
            <LoveTheSoundContent>
                <LoveTheSoundNumber>
                    {landingPageTexts('loveTheSoundNumber')}
                </LoveTheSoundNumber>
                <LoveTheSoundTitle>
                    {landingPageTexts('loveTheSoundTitle')}
                </LoveTheSoundTitle>
                <LoveTheSoundDescription>
                    {landingPageTexts('loveTheSoundContentText')}
                </LoveTheSoundDescription>
                <LoveTheSoundDownloadButton onClick={scrollToTop}>
                    {landingPageTexts('loveTheSoundDownload')}
                </LoveTheSoundDownloadButton>
            </LoveTheSoundContent>
        </LoveTheSoundContainer>
    )
}

export default LoveTheSound;
