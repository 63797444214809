import styled from 'styled-components';

export const FooterContainer = styled.footer`
    display: flex;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    font-size: 1.1rem;
    position: absolute;
    bottom: 0;

    & > a {
        color: #fff;
    }

    @media (max-width: 480px) {
        background-color: #2E56FF;
    }
`;
