import * as React from "react"

const YellowTriangle = () => (
  <svg
    width={567}
    height={664}
    fill="none"
  >
    <path
      d="M234.701 25.806c31.511-40.17 94.656-31.203 113.661 16.142l213.01 530.664c19.005 47.345-20.384 97.559-70.9 90.385l-566.199-80.411c-50.516-7.175-74.271-66.356-42.76-106.528L234.701 25.806Z"
      fill="#FEF7D4"
    />
  </svg>
)

export default YellowTriangle;
