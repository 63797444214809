import React from 'react';
import { LeaLogoContainer } from './LeaLogo.styles';
import LeaLogoImg from '../../assets/svg/LeaLogoImg.svg';
import LeaLogoImgMobile from '../../assets/svg/LeaLogoImgMobile.svg';

const LeaLogo = (props) => {
    return (
        <LeaLogoContainer padding={props.padding || '0'}>
            <img src={window.innerWidth <= 480 ? LeaLogoImgMobile : LeaLogoImg} alt="Lea logo in top" />
        </LeaLogoContainer>
    )
}

export default LeaLogo;
