import styled from 'styled-components';
import { theme } from '../../../assets/theme';

export const LoveTheSoundContainer = styled.div`
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    padding: 2rem 0;

    @media (max-width: ${theme.breakpoints.tablet}) {
        flex-direction: column;
        height: unset;
        padding: 0;
    }
`;

export const LoveTheSoundContent = styled.div`
    max-width: 28rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12%;
    row-gap: 1rem;
    color: ${theme.colors.blue};
    position: relative;
    z-index: 1;
    background-color: transparent;

    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 80%;
        margin: auto;
        padding-bottom: 3rem;
        justify-content: flex-end;
    }
`;

export const LoveTheSoundTitle = styled.p`
    font-weight: 300;
    font-size: 68px;
    line-height: 76px;
    margin-left: -0.3rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 40px;
        line-height: 48px;
    }
`;

export const LoveTheSoundNumber = styled.p`
    font-size: 16px;
`;

export const LoveTheSoundDescription = styled.p`
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const LoveTheSoundDownloadButton = styled.button`
    background-color: ${theme.colors.blue};
    border-radius: 37px;
    color: ${theme.colors.white};
    width: 205px;
    border: none;
    padding: 16px 40px;
    margin-top: 1rem;
`;

export const GroupOfPhonesWrapper = styled.div`
    max-height: 35rem;
    width: 100%;

    @media (max-width: ${theme.breakpoints.tablet}) {
        max-height: unset;
    }
`;

export const GroupOfPhonesImg = styled.img`
    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 100%;
        height: 75vh;
    }
`;
