import * as React from "react"

const YellowTriangleMobile = () => (
  <svg
    width={178}
    height={254}
    fill="none"
  >
    <path
      d="M51.326 10.114C63.33-5.187 87.381-1.77 94.62 16.264l81.138 202.133c7.239 18.034-7.765 37.161-27.007 34.428l-215.669-30.629c-19.242-2.733-28.29-25.276-16.288-40.577L51.326 10.114Z"
      fill="#FEF7D4"
    />
  </svg>
)

export default YellowTriangleMobile;
