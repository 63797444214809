import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { LoadingSpinner } from './components';
import GlobalStyles from './assets/styles/global';

const DownloadPage = lazy(() => import('./components/Download'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const LoggedIn = lazy(() => import('./pages/LoggedIn'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
// const UnsubscribePage = lazy(() => import('./pages/UnsubscribePage'));

const App = () => {
    const [loadingLandingPageContent, setLoadingLandingPageContent] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoadingLandingPageContent(false);
        }, 1000);
    }, []);

    return (
        <BrowserRouter>
            <GlobalStyles />
            <Switch>
                <Route exact path='/'>
                    {loadingLandingPageContent ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <Suspense fallback={<LoadingSpinner />}>
                                <LandingPage />
                            </Suspense>
                        </>
                    )}
                </Route>
                <Route exact path='/privacy'>
                    {loadingLandingPageContent ? (
                        <LoadingSpinner />
                    ) : (
                        <Suspense fallback={<LoadingSpinner />}>
                            <PrivacyPolicyPage />
                        </Suspense>
                    )}
                </Route>
                <Route exact path='/logged-in' component={LoggedIn}>
                    <Suspense fallback={<LoadingSpinner />}>
                        <LoggedIn />
                    </Suspense>
                </Route>
                <Route exact path='/download'>
                    <Suspense fallback={<LoadingSpinner />}>
                        <DownloadPage />
                    </Suspense>
                </Route>
                {/* <Route exact path="/unsubscribe" component={UnsubscribePage}>
          <Suspense fallback={<LoadingSpinner />}>
            <UnsubscribePage />
          </Suspense>
        </Route> */}
                <Route exact path='/terms-and-conditions'>
                    <Suspense fallback={<LoadingSpinner />}>
                        <TermsAndConditions />
                    </Suspense>
                </Route>
                <Route path='/404'>
                    <Suspense fallback={<LoadingSpinner />}>
                        <NotFoundPage />
                    </Suspense>
                </Route>
                <Redirect from='*' to='/404' />
            </Switch>
        </BrowserRouter>
    );
};

export default App;
