import { FooterContainer } from './Footer.styles';
import { Link } from 'react-router-dom';

const Footer = props => {
    return (
        <FooterContainer color={props.color}>
            <Link to="/privacy" target="_blank" title="Privacy Policy">Privacy Policy</Link>
        </FooterContainer>
    );
};

export default Footer;
