import styled, { keyframes } from 'styled-components';
import { theme } from '../../../assets/theme';

export const SignUpContainer = styled.div`
    min-height: 100vh;
    display: flex;
    margin-bottom: auto;
    position: relative;
`;

export const SignUpContentWrapper = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.blue};
    position: relative;
`;

export const SignUpTopContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;
    width: 50%;

    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 100%;
    }
`;

export const SignUpTextContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${theme.breakpoints.tablet}) {
        row-gap: 2rem;
    }
`;

export const SignUpTitle = styled.h1`
    font-size: 68px;
    line-height: 76px;
    font-weight: 300;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 40px;
        line-height: 48px;
    }
`;

export const SignUpDescription = styled.p`
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
    text-align: center;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const SignUpInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 0.5rem;
`;

export const SignUpInput = styled.input`
    border: 3px solid #2E56FF;
    border-radius: 12px;
    padding: 1rem;
    max-width: 25rem;
    width: 100%;
    background-color: transparent;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 20px;
        width: 100%;
    }
`;

export const TermsWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 25rem;
    column-gap: 0.5rem;
    align-items: center;
`;

export const Label = styled.label`
    display: flex;
    column-gap: 0.5rem;
    font-size: 20px;
    line-height: 28px;
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const Checkbox = styled.input.attrs({ type:
    "checkbox" })`
        position: absolute;
        left: -9999999999px;
        opacity: 0;
`;

export const CustomCheckbox = styled.span`
    height: 25px;
    width: 25px;
    background-color: ${props => props.checked ? theme.colors.blue : 'transparent'};
    border: 3px solid #2E56FF;
    border-radius: 5px;
    cursor: pointer;
`;

export const CheckmarkIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  visibility: ${props => props.checked ? 'visible' : 'hidden'};
`

export const SignUpButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        max-width: 25rem;
    }
`;

export const SignUpButton = styled.button`
    padding: 16px 40px;
    border-radius: 37px;
    background-color: ${theme.colors.blue};
    color: ${theme.colors.white};
    opacity: ${props => props.disabled ? '0.5' : '1'};
    border: none;
    width: 50%;
    font-size: 32px;
    cursor: ${props => !props.disabled ? 'pointer' : 'default'};

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 20px;
        width: 100%;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    bottom: -11rem;

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
        flex-direction: row;
        column-gap: 1rem;
        bottom: -22rem;
    }
`;

const fadeIn = keyframes`
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
    `;

export const SubmitErrorMessage = styled.div`
    max-width: 25rem;
    width: 100%;
    margin: 0;
    color: red;
    font-size: 0.8rem;
    animation: ${(p) => fadeIn} 300ms linear;
    transition: 300ms linear;

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
        bottom: 2rem;
        font-size: 1rem;
        width: 75%
  }
`;

export const SubmittedMessage = styled.p`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${(p) => theme.colors.blue};
    margin: 0 auto;
    height: 23vh;
    animation: ${(p) => fadeIn} 300ms linear;
    transition: 300ms linear;

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
        font-size: 2rem;
  }
`;

export const TermsLink = styled.a``;

export const SignUpBackground = styled.div`
  width: 130vw;
  height: 80vh;
  position: absolute;
  background-color: ${theme.colors.lightGreen};
  top: 10vh;
  bottom: 10vh;
  left: -10vw;
  right: -10vw;
  transform: rotate(-3deg);
`;
