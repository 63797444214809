import styled from "styled-components";
import { theme } from "../../assets/theme";

export const AppStoreButtonWrapper = styled.a`
  width: 160px;
  height: 50px;
  cursor: pointer;
  background-color: ${(p) => theme.colors.black};
  color: ${(p) => theme.colors.white};
  border: 1px solid #a6a6a6;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0px;
  text-decoration: none;
  margin-bottom: 1rem;

  @media screen and (min-width: ${theme.breakpoints.tablet}) {
    width: 179.25px;
    height: 60px;
  }
  @media screen and (min-width: ${theme.breakpoints.laptop}) {
    margin-bottom: 0;
    &:nth-of-type(1) {
      margin-right: 2.5rem;
    }
  }
`;

export const AppStoreIcon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 33px;
  width: 33px;
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: start;
`;

export const ButtonTitle = styled.div`
  font-size: 0.7em;
  font-weight: bold;
  margin-left: 0px;

  @media screen and (min-width: ${theme.breakpoints.tablet}) {
    font-size: 0.8em;
  }
`;

export const AppStoreName = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 0px;

  @media screen and (min-width: ${theme.breakpoints.tablet}) {
    font-size: 1.3em;
  }
`;
