import styled from "styled-components";
import { theme } from "../../assets/theme";

export const LeaLogoContainer = styled.div`
    display: flex;
    justify-content: ${props => props.justify};
    width: 100%;
    background-color: ${props => props.backgroundColor || theme.colors.blue};
    padding: 1.5rem 0 0 2rem;
`;
