import React, { useState, useEffect } from 'react';
import GreatTipsPhone from '../../../assets/svg/GreatTipsPhone.svg';
import GreatTipsMobilePhone from '../../../assets/svg/GreatTipsMobilePhone.svg';
import { landingPageTexts } from '../../../utils/textService';
import { scrollToTop } from '../../../utils/scrollToTop';
import { showCustomGeo } from '../../../utils/showCustomGeo';
import { YellowTriangle, YellowTriangleMobile } from '../../Geometrics/index';
import { 
    GreatTipsContainer,
    GreatTipsContent,
    GreatTipsNumber,
    GreatTipsTitle,
    GreatTipsDescription,
    GreatTipsPhoneImg,
    GreatTipsImageWrapper,
    GreatTipsButton,
    YellowTriangleWrapper
} from './GreatTips.styles';

const SimpleSteps = () => {
    const [isMobileView, setIsMobileView] = useState(false);

    const checkInnerWidth = () => {
        if (window.innerWidth < 540) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }

    useEffect(() => {
        checkInnerWidth();
    }, []);

    window.addEventListener('resize', () => {
        checkInnerWidth();
    });

    const renderCustomTriangle = () => {
        if (showCustomGeo()) {
            return (
                <YellowTriangleWrapper left={window.innerWidth < 540 ? '0' : '-25vw'}>
                    {window.innerWidth < 540 ? <YellowTriangleMobile /> : <YellowTriangle />}
                </YellowTriangleWrapper>
            )
        }
        return null;
    }

    return (
        <GreatTipsContainer>
            <GreatTipsImageWrapper>
                {renderCustomTriangle()}
                <GreatTipsPhoneImg
                    src={!isMobileView ? GreatTipsPhone : GreatTipsMobilePhone} 
                    marginTop={window.innerWidth < 540 ? '0' : '5rem'}
                />
            </GreatTipsImageWrapper>
            <GreatTipsContent>
                <GreatTipsNumber>
                    {landingPageTexts('greatTipsNumber')}
                </GreatTipsNumber>
                <GreatTipsTitle>
                    {landingPageTexts('greatTipsTitle')}
                </GreatTipsTitle>
                <GreatTipsDescription>
                    {landingPageTexts('greatTipsContentText')}
                </GreatTipsDescription>
                <GreatTipsButton onClick={scrollToTop}>
                    {landingPageTexts('tryItTodayButtonText')}
                </GreatTipsButton>
            </GreatTipsContent>
        </GreatTipsContainer>
    )
}

export default SimpleSteps;