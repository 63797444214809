import styled from 'styled-components';
import { theme } from '../../../assets/theme';

export const BottomViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh;
`;

export const BottomViewContentWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;
    margin-bottom: 3rem;
`;

export const BottomViewTitle = styled.h2`
    color: ${theme.colors.white};
    font-size: 40px;
    font-weight: 300;
`;

export const BottomViewTextContent = styled.p`
    color: ${theme.colors.white};
    font-weight: 600;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
`;

export const BottomViewEmailText = styled.span`
    font-weight: bold;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    bottom: -11rem;

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
        flex-direction: row;
        column-gap: 1rem;
        bottom: -22rem;
    }
`;
