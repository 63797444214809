import styled from 'styled-components';
import { theme } from '../../../assets/theme';

export const StartHearContainer = styled.div`
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    padding: 4rem;
`;

export const StartHearContentWrapper = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        height: inherit;
        justify-content: space-around;
    }
`;

export const StartHearTopEmptyDiv = styled.div`
    height: 10vh;
`;

export const StartHearTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    row-gap: 5rem;
`;

export const StartHearTitle = styled.h1`
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    text-align: center;
    max-width: 85%;

    @media screen and (min-width: ${theme.breakpoints.tablet}) {
        font-size: 90px;
        font-weight: 300;
        line-height: 76px;
    }
`;

export const StartHearDesciption = styled.p`
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    
    @media screen and (min-width: ${theme.breakpoints.tablet}) {
        max-width: 70%;
        font-size: 40px;
        font-weight: 300;
        line-height: 48px;
    }
`;

export const StartHearButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;

  @media screen and (min-width: ${theme.breakpoints.tablet}) {
    flex-direction: row;
    column-gap: 1rem;
  }
`;
