import styled, { keyframes } from "styled-components";
import { theme } from "../../assets/theme";

const bounceAnimation = keyframes`
    0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
`;

export const SpinnerWrapper = styled.div`
  margin: 30vh auto 0;
  width: 50vh;
  text-align: center;
`;

export const SpinnerDot = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${theme.colors.blue};
  margin: 8px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: ${bounceAnimation} 1.4s infinite ease-in-out both;
  animation: ${bounceAnimation} 1.4s infinite ease-in-out both;
  animation-delay: ${(p) => p.delay}s;
`;
