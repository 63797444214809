import styled from 'styled-components';
import { theme } from '../../../assets/theme';

export const GreatTipsContainer = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto 2rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        flex-direction: column-reverse;
        width: 100%;
        justify-content: unset;
    }
`;

export const GreatTipsContent = styled.div`
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: ${theme.colors.white};
    background-color: transparent;

    @media (max-width: ${theme.breakpoints.tablet}) {
        width: 100%;
        padding: 4rem;
        margin: 0;
        height: unset;
    }
`;

export const GreatTipsNumber = styled.p`
    font-size: 16px;
`;

export const GreatTipsTitle = styled.h1`
    font-weight: 300;
    font-size: 68px;
    line-height: 76px;
    margin-left: -0.3rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 40px;
        line-height: 48px;
    }
`;

export const GreatTipsDescription = styled.p`
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${theme.breakpoints.tablet}) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const GreatTipsImageWrapper = styled.div`
    display: flex;
    height: fit-content;

    @media (max-width: ${theme.breakpoints.tablet}) {
        height: unset;
        margin-bottom: 0;
        position: relative;
        width: 100vw;
        justify-content: center;
    }
`;

export const GreatTipsPhoneImg = styled.img`
    max-width: 40rem;
    max-height: 40rem;
    width: 100%;
    height: 100%;

    @media (max-width: ${theme.breakpoints.tablet}) {
        width: unset;
        height: unset;
        position: relative;
        margin-top: ${props => props.marginTop};
    }
`;

export const GreatTipsButton = styled.button`
    background-color: ${theme.colors.white};
    border-radius: 37px;
    color: ${theme.colors.blue};
    width: 205px;
    border: none;
    padding: 16px 40px;
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-weight: 600;
    display: block;

    @media (max-width: ${theme.breakpoints.tablet}) {
        display: none;
    }
`;

export const YellowTriangleWrapper = styled.div`
    position: absolute;
    top: 0;
    left: ${props => props.left};
`;
