import * as React from "react"

const RoundedPinkRectangleMobile = () => (
  <svg
    width={334}
    height={265}
    fill="none"
  >
    <path
      d="M420.749 223.595c-15.056 34.249-55.024 49.826-89.271 34.791L40.673 130.722C6.426 115.687-9.13 75.734 5.925 41.485 20.98 7.235 60.949-8.341 95.195 6.693l290.806 127.665c34.247 15.035 49.804 54.988 34.748 89.237Z"
      fill="#FDE5DF"
    />
  </svg>
)

export default RoundedPinkRectangleMobile;
