export const colors = {
  blue: "#2E56FF",
  white: "#FFFFFF",
  lightGreen: "#D8F6DF",
  lightYellow: "#FDFBDB",
  lightGreyBlue: "#EDF3F6",
  darkGrey: "#454B54",
  black: "#0C0D10",
  mistyRose: "#FDE5DF",
};
